import React from 'react';
import Layout from '../components/Layout';

import Dibujo3d from '../../static/images/solutions/linea_de_lavado/c1.png'

const Service_one = ({location}) => {
    const {state = {}} = location;
    return (
        <Layout>
            <div id="main">
                <div className="services_section_banner">
                    <div
                        className="services_banner"
                        style={{
                            backgroundImage: `url(../images/solutions/linea_de_lavado/1.jpg)`,
                        }}
                    >
                        <h1>Línea de producción de lavado y reciclaje de botellas de PET de grado alimenticio</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Antecedentes</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: '#000'}}>
                                Los recursos mundiales de PET se están agotando, pero su demanda aumenta año tras año.
                                La sobreexplotación del petróleo ha causado un daño irreparable a la naturaleza y la
                                tierra, los seres humanos estamos sufriendo por la naturaleza.
                                <br/>
                                El desarrollo sostenible de la recuperación de recursos de PET ha sido un tema
                                importante en la industria del reciclaje de PET. La plena utilización de los recursos de
                                PET y la recuperación del 100% de los recursos de PET para lograr un desarrollo
                                sostenible es el objetivo final del reciclaje. En la actualidad, el proceso principal
                                para llevar a cabo el reciclaje de botellas (envases) de PET es el reciclaje físico. Las
                                botellas de PET postconsumo se recolectan en zonas urbanas, se reciclan en escamas de
                                rPET mediante el lavado y trituración, las cuales se pueden usar en escamas de rPET para
                                fabricar chips de calidad alimentaria. Este tipo de escamas de rPET cumple con el
                                estándar de la FDA que está disponible para entrar en contacto con alimentos y bebidas
                                directamente.
                            </p>
                            <p style={{color: '#000'}}>
                                Se encuentran disponibles 3 tipos especializados de rompe balas que incluyen dos ejes,
                                tres ejes y cuatro ejes para diferentes diseños de proceso. La velocidad del
                                transportador de alimentación está controlada por el inversor, es mejor evitar la
                                obstrucción del material. El rompedor de balas con tres ejes es particularmente fácil
                                para desarmar pacas de botellas de alta densidad. El rompedor de pacas de Zion Tech es
                                fácil de operar, funciona con bajo consumo de energía y menos mano de obra
                            </p>
                            <br/>
                            <p style={{color: '#000'}}>
                                El reciclaje de botellas de PET de grado botella a botella hace realidad el desarrollo
                                sostenible de la recuperación de recursos de botellas de PET.
                            </p>
                            <br/>
                            <p>
                                * Tomando como ejemplo una botella de Coca-Cola de 600 ml, cada 100 botellas de
                                Coca-Cola recuperadas puede reducir el uso de petróleo en 1.620 ml, lo que a su vez
                                reduce las emisiones de dióxido de carbono en 6.340 g. (Icono)"</p>
                        </div>
                    </div>
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Característica de producto</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: '#000'}}>
                                La línea de reciclaje de botellas de PET de grado alimenticio se utiliza principalmente
                                para proyectos de PET de botella a botella (aprobación de la FDA), que recicla botellas
                                de PET postconsumo en escamas de rPET. Las escamas de PET regenerativo cumplen con los
                                requisitos de índice de calidad del envasado de alimentos posterior y se utilizan para
                                el soplado y el envasado de botellas que pueden entrar en contacto directo con los
                                alimentos después de los procesos de peletización y SSP en la última etapa.
                            </p>
                            <br/>
                            <p style={{color: '#000'}}>
                                El proceso de reciclaje de botellas de PET de grado alimenticio es más complicado que el
                                reciclaje de PET normal, y los requisitos de equipos y la calidad de las escamas de rPET
                                finales son más estrictos. Al mismo tiempo, el propietario debe contar con una mayor
                                gestión de la fábrica y un conocimiento avanzado de la producción. La línea de lavado de
                                grado alimenticio de Zion Tech tiene una mayor automatización para evitar errores
                                humanos. Ultra-limpieza de botellas y escamas de PET, mezcla eficiente y un sistema de
                                inspección en línea para garantizar que la producción de escamas de PET reciclado cumpla
                                con el estándar del proceso de botella a botella de PET de grado alimenticio (aprobación
                                de la FDA). Mientras tanto, el proceso del sistema de reciclaje de botellas de PET de
                                grado alimenticio de Zion Tech se optimiza de acuerdo con la condición de la botella de
                                PET posconsumo de diferentes países con el costo económico, menos ocupación de espacio y
                                la tecnología de proceso más avanzada para satisfacer la variedad de aplicaciones de
                                producción posteriores. Con más de 20 años de experiencia en la producción de reciclaje
                                de botellas de PET, Zion Tech ofrece a los clientes orientación sobre procesos y
                                producción en línea.
                            </p>
                            <br/>
                            <p style={{color: '#000'}}>
                                El sistema de reciclaje de botellas de PET de Zion Tech adopta un sistema SCADA combinado
                                con un controlador PLC, que puede mostrar los datos de los parámetros de cada unidad y
                                equipo individual de manera intuitiva y efectiva, y al mismo tiempo el sistema puede
                                registrar todos los datos de producción, y ha combinado la comunicación remota.
                                tecnología para permitir la comunicación entre los clientes y los ingenieros de Zion Tech
                                a través de Internet y brindarles orientación en tiempo real. Zion Tech puede ayudar a
                                los clientes con la gestión práctica de la producción, el análisis de datos y el
                                mantenimiento diario del equipo. Además, todo el sistema de botellas de PET está
                                equipado con un clasificador óptico de botellas y un clasificador de escamas, lo que
                                reduce en gran medida la inversión en mano de obra y mejora la eficiencia de la
                                producción, lo que realmente logra un sistema de reciclaje limpio y altamente
                                automatizado.
                            </p>
                            <br/>
                            <p style={{color: '#000'}}>
                                Las pacas de botellas de PET se clasifican, trituran y lavan mediante la línea de lavado
                                de botellas de PET de grado alimenticio y, finalmente, se generan escamas de rPET de
                                alta calidad. Varios clientes conocidos de Zion Tech han obtenido la aprobación de la FDA
                                para las escamas de rPET y suministran estas escamas de PET a los fabricantes de bebidas
                                de marcas posteriores.
                            </p>
                            <br/>
                            <p style={{color: '#000'}}>
                                Zion Tech analiza botellas de PET posconsumo de varios países y está desarrollando un
                                sistema de reciclaje de botellas de PET de proceso corto (compacto) para estas botellas
                                de PET posconsumo en países y regiones específicos. El sistema de desarrollo integra
                                múltiples funciones de una sola máquina y reduce en gran medida la ocupación de espacio
                                y la inversión. Las escamas de rPET recicladas por línea compacta también pueden cumplir
                                con los requisitos de la aplicación de grado alimenticio y las aplicaciones de grado de
                                filamento de micro-denier. Se lanzará a finales de 2020.
                            </p>
                        </div>
                    </div>
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Destacar</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: '#000'}}>
                                <ul>
                                    <li>Soluciones para la diversificación de fardos de botellas de PET;</li>
                                </ul>
                                <ul>
                                    <li>Tecnología de rompe fardos automático, reduce la mano de obra, una fuerte
                                        capacidad de rotura de pacas de botellas de PET de alta densidad;
                                    </li>
                                </ul>
                                <ul>
                                    <li>Tecnología patentada para la eliminación de etiquetas de PVC;</li>
                                </ul>
                                <ul>
                                    <li>Proceso de prelavado y lavado en caliente, patentado para garantizar el lavado
                                        de las escamas de rPET;
                                    </li>
                                </ul>
                                <ul>
                                    <li>Tecnología y capacidad integradas para la máquina clasificadora automática y la
                                        línea de reciclaje de botellas de PET;
                                    </li>
                                </ul>
                                <ul>
                                    <li>El proceso de lavado en caliente reduce el contenido de impurezas para lograr un
                                        índice de calidad alimentaria;
                                    </li>
                                </ul>
                                <ul>
                                    <li>Calidad estable y confiable de las escamas de rPET, inspección en línea para
                                        garantizar la estabilidad de las escamas de rPET;
                                    </li>
                                </ul>
                                <ul>
                                    <li>Sistema de tratamiento de reciclaje de agua en línea completo para prelavado,
                                        lavado en caliente y enjuague.
                                    </li>
                                </ul>
                                <ul>
                                    <li>Desglose de operación bajo, servicio de orientación de gestión de producción
                                        para el cliente;
                                    </li>
                                </ul>
                            </p>
                        </div>
                    </div>
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Capacidad de entrada</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: '#000'}}>
                                1500-10000 kg/h
                            </p>
                        </div>
                    </div>
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Opciones de modelo</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <img src={Dibujo3d} style={{width: "100%"}}/>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Service_one;
